import React, { Component } from 'react';

import s from './index.scss';

export default class Compatible extends Component {
  render() {
    return (
      <div className={s.compatible}>
        {this.props.data.firefoxExtensionUrl && (
          <a
            href={this.props.data.firefoxExtensionUrl}
            target="_blank"
            className={s.browser + ' ' + s.browser_firefox}
            data-weather="browsers::firefox"
          />
        )}
        {this.props.data.operaExtensionUrl && (
          <a
            href={this.props.data.operaExtensionUrl}
            target="_blank"
            className={s.browser + ' ' + s.browser_opera}
            data-weather="browsers::opera"
          />
        )}
        {this.props.data.chromeExtensionUrl && (
          <a
            href={this.props.data.chromeExtensionUrl}
            target="_blank"
            className={s.browser + ' ' + s.browser_chrome}
            data-weather="browsers::chrome"
          />
        )}
        <div className={s.label}>Доступно для</div>
      </div>
    );
  }
}
