export default {
  /**
   * @param {string} landingName
   * @return {object}
   */
  getLandingConfig: function getLandingConfig(id, config) {
    return config.landings.find(el => el.id === id);
  },

  SLIDE_COUNT: 3,
  SLIDE_SHOW_DURATION: 10000,
  SLIDE_CHANGE_ANIMATION_DURATION: 750,

  WIDGETS_URL: 'https://nova.rambler.ru/mixinData/addons?json=1&limit=4',

  siteTitle: 'Рамблер/софт',

  landings: [
    {
      id: 'media',
      name: 'Рамблер/медиа',
      pageTitle: 'Рамблер/медиа',
      pageDescription:
        'Продвинутая Новая вкладка с виджетами, интеллектуальными рекомендациями и подборкой любимых сайтов',
      pageOgDescription:
        'Расширение для Chrome добавляет на Новую вкладку полезные виджеты и интеллектуальную подборку статей по вашим интересам.',
      ogTitle: 'Рамблер/медиа. Сделайте свой браузер персональным',
      domainName: 'media',
      chromeExtensionUrl:
        'https://chrome.google.com/webstore/detail/eflnfgjcfmhdpcaadiimlmclcmjpeaki',
      extensionInstallText:
        'После установки Рамблер/медиа просто откройте Новую вкладку в\u00A0браузере. Вас будут ждать полезные виджеты и лента персональных рекомендаций.',
      slidesContent: [
        {
          title: 'Сделай свой браузер<br/> <nobr>по-настоящему</nobr> удобным',
          description:
            'Погода, пробки, почта, курсы валют,<br/> новости и&#160;персональные рекомендации<br/> в&#160;Новой вкладке.',
        },

        {
          title: 'Самое важное<br/>в&#160;Новой вкладке',
          description:
            'Погода, курсы валют, пробки<br/>и&#160;новые письма — узнайте обо всём<br/>из&#160;Новой вкладки.',
        },

        {
          title: 'Ваш персональный<br/>Интернет',
          description:
            'Вас ждет ежедневная<br/>интеллектуальная подборка статей<br/>по&#160;вашим интересам.',
        },
      ],
    },
    {
      id: 'start',
      name: 'Рамблер/старт',
      pageTitle: 'Рамблер/старт',
      pageDescription:
        'Продвинутая Новая вкладка с виджетами, интеллектуальными рекомендациями и подборкой любимых сайтов',
      pageOgDescription:
        'Расширение для Chrome добавляет на Новую вкладку полезные виджеты и интеллектуальную подборку статей по вашим интересам.',
      ogTitle: 'Рамблер/старт. Сделайте свой браузер персональным',
      domainName: 'start',
      chromeExtensionUrl:
        'https://chrome.google.com/webstore/detail/gignacddmmmefainlfmefklgboboiebj',
      extensionInstallText:
        'После установки Рамблер/старта просто откройте Новую вкладку в\u00A0браузере. Вас будут ждать полезные виджеты и лента персональных рекомендаций.',
      slidesContent: [
        {
          title:
            'Сделай свой браузер<br/> <nobr>по-настоящему</nobr> персональным',
          description:
            'Погода, пробки, почта, курсы валют,<br/> новости и&#160;персональные рекомендации<br/> в&#160;Новой вкладке.',
        },
        {
          title: 'Самое важное<br/>в&#160;Новой вкладке',
          description:
            'Погода, курсы валют, пробки<br/>и&#160;новые письма — узнайте обо всём<br/>из&#160;Новой вкладки. Ваши любимые сайты<br/>всегда под рукой.',
        },

        {
          title: 'Ваш персональный<br/>Интернет',
          description:
            'Вас ждет ежедневная<br/>интеллектуальная подборка статей<br/>по&#160;вашим интересам.',
        },
      ],
    },
    {
      id: 'games',
      name: 'Рамблер/игры',
      pageTitle: 'Рамблер/игры',
      pageDescription:
        'Каталог бесплатных игр сервиса Рамблер/игры, в которые можно играть онлайн прямо в браузере',
      pageOgDescription:
        'Каталог бесплатных игр, в которые можно играть прямо в браузере.',
      domainName: 'games',
      chromeExtensionUrl:
        'https://chrome.google.com/webstore/detail/eoghdpiolkfmmlojbeepkmpdlbiejlpl',
      extensionInstallText: '',
      helpUrl: 'https://help.rambler.ru/feedback/games/',
      legalUrl: 'https://help.rambler.ru/games/games-legal/1578/',
      slidesContent: [
        {
          title: 'Развлечения,<br>которые всегда с вами',
          description:
            'Рамблер/игры не требуют установки<br>и работают прямо в браузере.',
        },
        {
          title: 'С любимыми<br>не расставайтесь',
          description:
            'Добавьте любимые игры в Избранное<br>для мгновенного доступа к ним.',
        },
        {
          title: 'Подборка новостей<br>и обзоров',
          description:
            'Читайте новости игровой индустрии,<br>чтобы быть в курсе горячих новинок.',
        },
      ],
    },
    {
      id: 'weather',
      name: 'Рамблер/погода',
      pageTitle: 'Рамблер/погода',
      pageDescription:
        'Мгновенный доступ к прогнозу погоды в любом месте планеты',
      pageOgDescription:
        'Прогноз погоды в вашем городе 24/7. Рамблер/погода в любом месте планеты. Дождь, снег или жаркое солнце – расширение Рамблер/погода подготовит вас к любым капризам природы всего за один клик.',
      domainName: 'weather',
      firefoxExtensionUrl:
        'https://addons.mozilla.org/ru/firefox/addon/ramblerweather/',
      operaExtensionUrl:
        'https://addons.opera.com/en/extensions/details/bb53dd2dd8d7436fcafebd384e03de0ec7460c92/',
      chromeExtensionUrl:
        'https://chrome.google.com/webstore/detail/mnellcecpgpeniolniglgfdfphfeoffo',
      extensionInstallText: '',
      helpUrl: 'https://help.rambler.ru/feedback/weather/',
      legalUrl: 'https://help.rambler.ru/weather/weather-legal/1576/',
      slidesContent: [
        {
          title: 'Следим<br>за погодой 24/7',
          description:
            'Точный и понятный прогноз<br>на ближайшее время для<br>вашего города.',
        },
        {
          title: 'Знаем все города<br>на планете',
          description:
            'Для Рамблер/погоды нет белых пятен<br>на карте — мы знаем погоду в любом городе<br>или его окрестностях.',
        },
        {
          title: 'Погода там,<br>где вас нет',
          description:
            'Предсказываем погоду в самых<br>непредсказуемых местах — следите<br>за прогнозами по всему миру.',
        },
      ],
    },
  ],
};
