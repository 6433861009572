import React, { Component } from 'react';
import PropTypes from 'prop-types';

import s from './styles.scss';

export default class SplashScreen extends Component {
  render() {
    return (
      <div
        className={
          s['b-splash-screen'] +
          ' ' +
          s[this.props.visible ? 'b-splash-screen-visible' : '']
        }
      >
        <div className={s['b-splash-screen__wrapper']}>
          <div className={s['b-arrow']}>
            <div className={s['b-arrow__icon']} />
          </div>
          <p className={s['b-install-title']}>
            Установите расширение,<br />
            чтобы началась магия
          </p>
          <p className={s['b-install-description']}>{this.props.installText}</p>
        </div>
      </div>
    );
  }
}

SplashScreen.propTypes = {
  visible: PropTypes.bool.isRequired,
  installText: PropTypes.string.isRequired,
};
