import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import SplashScreen from '../SplashScreen';
import { updateStartHref } from './helpers';

import config from '../../config';

import s from './index.scss';

import {
  goal,
  START_CLICK_INSTALL,
  START_5_SCREENS_WATCHED,
} from '../../../common/analytics';

class InstallButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splashScreenVisible: false,
    };
    this.handleInstallClick = this.handleInstallClick.bind(this);
  }

  componentDidMount() {
    if (this.props.go) {
      const go = () => {
        if (window.location.href === '#go' && this.el) {
          this.el.click();
        }
      };
      window.addEventListener('hashchange', go);
      go();
    }
  }

  /**
   * Быстрая устрановка плагина без перехода в Google Chrome Store
   * @param e
   */
  handleInstallClick(e) {
    goal(START_CLICK_INSTALL);
  }

  render() {
    return (
      <React.Fragment>
        {/* Ссылка на расширение "Рамблер/старт" в магазине расширений Google Chrome Store. */}
        <a
          href={this.props.installUrl}
          target="_blank"
          onClick={this.handleInstallClick}
          className={s['b-button'] + ' ' + s['b-button__active']}
          data-ui={`install::${this.props.index || '0'}`}
          ref={el => (this.el = el)}
        >
          Установить
        </a>
        <SplashScreen
          visible={this.state.splashScreenVisible}
          installText={this.props.installText}
        />
      </React.Fragment>
    );
  }
}

InstallButton.propTypes = {
  index: PropTypes.string,
  installUrl: PropTypes.string.isRequired,
  go: PropTypes.bool,
  installText: PropTypes.string.isRequired,
};

class Slide extends Component {
  render() {
    const { title, text, index } = this.props;

    return (
      <div className={s['b-stage']}>
        <a name={'page' + index} id={'page' + index} />
        <div className={s['b-stage__wrapper']}>
          <div className={s['b-promo'] + ' ' + s['b-promo__' + index]}>
            <h1
              className={s['b-title'] + ' ' + s['b-title__' + index]}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className={s['b-text'] + ' ' + s['b-text__' + index]}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {this.props.children}
          </div>
          <div className={s['b-slide'] + ' ' + s['b-slide__' + index]}>
            <div
              className={s['b-slide__img'] + ' ' + s['b-slide__img-' + index]}
            />
          </div>
        </div>
      </div>
    );
  }
}

Slide.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
};

class AppStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      clickCounter: 0,
    };
    this.handleWheel = this.handleWheel.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.addEventListener('wheel', this.handleWheel);
  }

  scrollToElement(el) {
    const EL_TOP = el.offsetTop;
    const DURATION = 500;
    const INCREMENT = 20;
    const element = document.scrollingElement || document.documentElement;
    const start = element.scrollTop;
    const change = EL_TOP - start;
    let currentTime = 0;
    const animateScroll = () => {
      currentTime += INCREMENT;
      element.scrollTop = this.easeInOutQuad(
        currentTime,
        start,
        change,
        DURATION
      );
      if (currentTime < DURATION) {
        setTimeout(animateScroll, INCREMENT);
      }
    };
    animateScroll();
  }

  easeInOutQuad(time, startValue, changeInValue, duration) {
    time /= duration / 2;
    if (time < 1) {
      return changeInValue / 2 * time * time + startValue;
    }
    time--;
    return -changeInValue / 2 * (time * (time - 2) - 1) + startValue;
  }

  // Скролл мыши
  handleWheel(e) {
    const halfViewportHeight = window.innerHeight / 2;
    const pages = document.querySelectorAll('.' + s['b-stage']);
    const heights = Array.prototype.map.call(pages, function(el, i) {
      const rect = el.getBoundingClientRect();
      return { el, rect };
    });
    let i;
    let topVal = Math.abs(heights[0].rect.top);
    for (i = 1; i < heights.length; i++) {
      topVal = Math.min(topVal, Math.abs(heights[i].rect.top));
    }
    this.setState({
      page: Array.prototype.findIndex.call(heights, function(el) {
        return Math.abs(el.rect.top) === topVal;
      }),
    });
  }

  // Навигация по точкам
  handleScroll(e, page) {
    e.preventDefault();

    // не прокручиваем при клике на текущую страницу
    if (this.state.page === page) {
      return false;
    }

    this.setState(
      {
        // Переключаемся на указанную страницу (мгновенно, без анимации).
        page: page,
      },
      () => {
        const pages = document.querySelectorAll('.' + s['b-stage']);
        this.scrollToElement(pages[page]);
      }
    );
  }

  render() {
    const { page } = this.state;

    // Анимация последней страницы и футера
    if (page >= 2) {
      goal(START_5_SCREENS_WATCHED);
    }

    const title = 'Рамблер/старт – ' + config.siteTitle;

    return (
      <div className={s['b-wrapper']}>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <link
            rel="chrome-webstore-item"
            href="https://chrome.google.com/webstore/detail/gignacddmmmefainlfmefklgboboiebj"
          />
        </Helmet>
        <div className={s['b-sliders']}>
          {this.props.slides.map(
            (i, index) =>
              index === 0 ? (
                <div
                  key={index}
                  className={s['b-stage'] + ' ' + s['b-stage__main']}
                >
                  <a name="page0" />
                  <div className={s['b-stage__wrapper']}>
                    <div
                      className={
                        s['b-logo'] + ' ' + s['b-logo_' + this.props.project]
                      }
                    >
                      {this.props.name}
                    </div>

                    <div className={s['b-promo'] + ' ' + s['b-promo__main']}>
                      <h1
                        className={s['b-title'] + ' ' + s['b-title__main']}
                        dangerouslySetInnerHTML={{
                          __html: i.title,
                        }}
                      />
                      <div
                        className={s['b-text']}
                        dangerouslySetInnerHTML={{
                          __html: i.description,
                        }}
                      />
                      <InstallButton
                        installUrl={this.props.installUrl}
                        index={index.toString()}
                        go
                        installText={this.props.installText}
                      />
                      <br />
                      <a
                        href="/browser-exntensions"
                        className={s.back}
                        data-soft={'product::' + this.props.type + '::back'}
                      >
                        К списку расширений
                      </a>
                    </div>
                    <div className={s['b-collage']}>
                      <div className={s['b-collage__simple']} />
                      <div className={s['b-collage__simple2']} />
                      <div className={s['b-collage__settings']} />
                      <div className={s['b-collage__lenta']} />
                      <div className={s['b-collage__rambler']} />
                      <div className={s['b-collage__plus']} />
                      <div className={s['b-collage__games']} />
                      <div className={s['b-collage__meal']} />
                      <div className={s['b-collage__mail']} />
                      <div className={s['b-collage__afisha']} />
                    </div>
                  </div>
                </div>
              ) : (
                <Slide
                  key={index}
                  title={i.title}
                  text={i.description}
                  index={index.toString()}
                >
                  <InstallButton
                    installUrl={this.props.installUrl}
                    index={index.toString()}
                    installText={this.props.installText}
                  />
                  <br />
                  <a
                    href="/"
                    className={s.back}
                    data-soft={'product::' + this.props.type + '::back'}
                  >
                    К списку расширений
                  </a>
                </Slide>
              )
          )}
        </div>

        {
          // Кнопки-точки для мгновенного переключения слайдов.
          <ul className={s['b-navigate']}>
            {this.props.slides.map((page, index) => {
              const className =
                s['b-navigate__item'] +
                ' ' +
                (this.state.page === index ? s['b-navigate__item-active'] : '');

              return (
                <li className={className} key={index}>
                  <a
                    className={s['b-navigate__link']}
                    href={'#page' + index}
                    onClick={e => this.handleScroll(e, index)}
                  />
                </li>
              );
            })}
          </ul>
        }
      </div>
    );
  }
}

AppStart.propTypes = {
  installUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AppStart;
