import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Compatible from '../Compatible';
import Slideshow from '../Slideshow';

import textConfig from '../../config';
import config from '../../landings-data';
const weatherConfig = config.getLandingConfig('weather', config);

import s from './index.scss';

export default class AppWeather extends Component {
  render() {
    const title = 'Рамблер/погода – ' + textConfig.siteTitle;
    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        <Compatible data={weatherConfig} />
        <div id="slideshow-weather" className={s.slideshow}>
          <Slideshow type="weather" data={weatherConfig} />
        </div>
      </React.Fragment>
    );
  }
}
