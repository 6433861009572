import * as React from 'react';

import { Top100, GA, NewMetrika, Tns, LiveInternet } from '../Counters';
import { routes } from './routes';

export default class Layout extends React.Component {
  render() {
    const route = routes.find(route => route.path === this.props.location);
    return (
      <React.Fragment>
        {route ? <route.component /> : null}
        <Top100 id={929011} />
        <Top100 id={7728281} />
        {/*<GA id={'UA-21393121-1'} />*/}
        <NewMetrika ids={[26557842, 26649402]} />
        <Tns />
        <LiveInternet />
      </React.Fragment>
    );
  }
}
