import React, { Component } from 'react';

import App from './content.jsx';

import config from '../../landings-data';
const startConfig = config.getLandingConfig('start', config);

export class AppStart extends Component {
  render() {
    return (
      <React.Fragment>
        <App
          slides={startConfig.slidesContent}
          installUrl={startConfig.chromeExtensionUrl}
          installText={startConfig.extensionInstallText}
          name={startConfig.name}
          project={startConfig.domainName}
        />
      </React.Fragment>
    );
  }
}

export default class AppStartWrapper extends Component {
  render() {
    return (
      <div id="app-start">
        <AppStart />
      </div>
    );
  }
}
