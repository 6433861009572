import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Footer from '@rambler-components/footer';
import '@rambler-components/footer/styles.css?compiled';

import s from './style.scss';

const simpleLinks = [
  {
    label: 'Помощь',
    url: 'https://help.rambler.ru/feedback/soft/',
  },
  {
    label: 'Вакансии',
    url: 'https://rambler-co.ru/jobs',
  },
  {
    label: 'Условия использования',
    url: 'https://help.rambler.ru/legal/1430',
  },
  {
    label: 'Политика конфиденциальности',
    url: 'https://help.rambler.ru/legal/1142',
  },
];

const socialLinks = [
  {
    code: 'vk',
    url: 'https://vk.com/rambler',
  },
  {
    code: 'ok',
    url: 'https://ok.ru/rambler',
  },
  {
    code: 'tg',
    url: 'https://t.me/news_rambler',
  },
  {
    code: 'rss',
    url: 'https://news.rambler.ru/rss/',
  },
];

class FooterComponent extends Component {
  render() {
    return (
      <div className={s.root}>
        <Footer
          simpleLinks={simpleLinks}
          socialLinks={socialLinks}
          ageLimit={18}
        />
      </div>
    );
  }
}

FooterComponent.propTypes = {
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string.isRequired,
  }).isRequired,
};

export default FooterComponent;
