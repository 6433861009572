import React, { Component } from 'react';
import PropTypes from 'prop-types';

import s from './index.scss';

class Help extends Component {
  render() {
    return (
      <div
        className={
          s.help +
          ' ' +
          (this.props.theme ? s['theme_' + this.props.theme] : '')
        }
      >
        <div className={s.help_content}>
          <div className={s.help_item}>
            <a
              href="https://help.rambler.ru/legal/soft/"
              className={s.help_item_title}
              target="_blank"
              data-soft={
                this.props.stat.appPlatformType + '::bottom_menu::legal'
              }
            >
              Пользовательское соглашение
            </a>
            <div className={s.help_item_description}>
              Устанавливая приложение или расширение, вы принимаете соглашение
            </div>
          </div>
          <div className={s.help_item}>
            <a
              href="https://help.rambler.ru/feedback/soft/"
              className={s.help_item_title}
              target="_blank"
              data-soft={
                this.props.stat.appPlatformType + '::bottom_menu::help'
              }
            >
              Техническая поддержка
            </a>
            <div className={s.help_item_description}>
              В любой непонятной ситуации с приложениями Рамблер/софт пишите нам
            </div>
          </div>
          <div className={s.help_item}>
            <a
              href="https://help.rambler.ru/feedback/soft/"
              className={s.help_item_title}
              target="_blank"
              data-soft={
                this.props.stat.appPlatformType + '::bottom_menu::feedback'
              }
            >
              Обратная связь
            </a>
            <div className={s.help_item_description}>
              Свои отзывы и пожелания отправляйте нам через форму обратной связи
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Help.propTypes = {
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string.isRequired,
  }).isRequired,
};

export default Help;
