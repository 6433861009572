import React from 'react';
import Helmet from 'react-helmet';

import Catalog from '../Catalog';
import {
  AppMailM,
  // AppNews,
  AppDating,
  // AppKassa,
  // AppMediaUp,
  // AppRamblerApp,
  AppHoroscopes,
  // AppMedia,
  AppAliceHoroscopes
} from '../AppPage';
import AppWeather from '../AppWeather';
import AppStart from '../AppStart';

import Header from '../Header';
import { SliderWithAllProducts } from '../Slider';
import Help from '../Help';
import Footer from '../Footer';

// import YandexBrowser from '../YandexBrowser';
// import YandexBrowserWelcome from '../YandexBrowserWelcome';

import config from '../../config';
import data from '../../data';

import '../../site.css';

const SITE_URL = process.env.SITE_URL;

export const routes = [
  // Страницы категорий.
  ...data.categories.map(i => ({
    path: '/' + i.path,
    component: () => {
      return (
        <React.Fragment>
          <Helmet>
            <title>{i.title + ' – ' + config.siteTitle}</title>
            <meta
              property="og:image"
              content={SITE_URL + require('../../images/og_default_1200x630.png')}
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
          </Helmet>
          <div id="header-container">
            <Header
              path={i.path}
              currentServerTime={Date.now()}
            />
          </div>
          <div id="slider">
            <SliderWithAllProducts
              type={i.path ? i.path : 'all'}
              stat={{ appPlatformType: i.path ? i.path : 'all', place: 'slider' }}
            />
          </div>
          <div id="catalog">
            <Catalog
              title={i.title}
              apps={data.pages.filter(
                app => app.type === i.path || i.path === ''
              )}
              stat={{ appPlatformType: i.path ? i.path : 'all' }}
            />
          </div>
          <Help stat={{ appPlatformType: i.path ? i.path : 'all' }} />
          <Footer stat={{ appPlatformType: i.path ? i.path : 'all' }} />
        </React.Fragment>
      );
    },
  })),

  // Страницы продуктов cо стандартным шаблоном.
  ...data.pages.filter(i => ![/*'yandex-browser'*/].includes(i.id)).map(i => ({
    path: '/' + i.id,
    component: () => {
      let AppPageComponent;
      let HelpTheme;
      switch (i.id) {
        case 'mail_m':
          AppPageComponent = AppMailM;
          break;
        // case 'news':
        //   AppPageComponent = AppNews;
          break;
        case 'dating':
          AppPageComponent = AppDating;
          break;
        // case 'kassa':
        //   AppPageComponent = AppKassa;
        //   break;
        // case 'mediaup':
        //   AppPageComponent = AppMediaUp;
        //   break;
        // case 'rambler-app':
        //   AppPageComponent = AppRamblerApp;
        //   break;
        case 'horoscopes':
          AppPageComponent = AppHoroscopes;
          break;
        case 'main':
          AppPageComponent = AppMain;
          break;
        // case 'media':
        //   AppPageComponent = AppMedia;
        //   break;
        case 'weather':
          AppPageComponent = AppWeather;
          HelpTheme = 'white';
          break;
        case 'start':
          AppPageComponent = AppStart;
          HelpTheme = 'white';
          break;
        case 'alice-horoscopes':
          AppPageComponent = AppAliceHoroscopes;
          break;
        default:
          AppPageComponent = () => <div>Component for {i.id} not set</div>;
      }

      return (
        <React.Fragment>
          <Helmet>
            <meta
              property="og:image"
              content={
                SITE_URL + require('../../images/og_' + i.id + '_1200x630.png')
              }
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
          </Helmet>
          <div id="header-container">
            <Header
              path={i.id}
              currentServerTime={Date.now()}
            />
          </div>
          <AppPageComponent />
          <Help stat={{ appPlatformType: i.type }} theme={HelpTheme} />
          <Footer stat={{ appPlatformType: i.type }} />
        </React.Fragment>
      );
    },
  })),

  // Особые страницы.
  // {
  //   path: '/yandex-browser',
  //   exact: true,
  //   component: () => (
  //     <React.Fragment>
  //       <Helmet>
  //         <meta
  //           property="og:image"
  //           content={
  //             SITE_URL + require('../../images/og_yandex-browser_1200x630.png')
  //           }
  //         />
  //         <meta property="og:image:width" content="1200" />
  //         <meta property="og:image:height" content="630" />
  //       </Helmet>
  //       <div id="topline-container" data-server-time={Date.now()}>
  //         <Topline currentServerTime={Date.now()} />
  //       </div>
  //       <div id="menu">
  //         <Menu location={'yandex-browser'} stat={{ appPlatformType: 'desktop' }} />
  //       </div>
  //       <YandexBrowser />
  //       <Help stat={{ appPlatformType: 'desktop' }} />
  //       <Footer stat={{ appPlatformType: 'desktop' }} />
  //     </React.Fragment>
  //   ),
  // },
  // {
  //   path: '/yabrowser/welcome',
  //   exact: true,
  //   component: YandexBrowserWelcome,
  // },
];
