import React, { Component } from 'react';

import StoreLink from '../StoreLink';

import SLIDE_COUNT from '../../landings-data';
import SLIDE_SHOW_DURATION from '../../landings-data';
import SLIDE_CHANGE_ANIMATION_DURATION from '../../landings-data';

import s from './index.scss';

let $ui = {};
let currentSlide = 1;
let slideShowTimeout;
let slideHideTimeout;

const Slideshow = {
  eventHandlers: {
    keyboardHandler(e) {
      const KEYCODE_SPACE = 32;
      const KEYCODE_LEFT = 37;
      const KEYCODE_UP = 38;
      const KEYCODE_RIGHT = 39;
      const KEYCODE_DOWN = 40;
      if (
        e.keyCode === KEYCODE_SPACE ||
        e.keyCode === KEYCODE_DOWN ||
        e.keyCode === KEYCODE_RIGHT
      ) {
        e.preventDefault();
        Slideshow.nextSlide();
      }
      if (e.keyCode === KEYCODE_UP || e.keyCode === KEYCODE_LEFT) {
        e.preventDefault();
        Slideshow.prevSlide();
      }
      return false;
    },

    navigationClickHandler(e) {
      const id = parseInt(e.currentTarget.dataset.id);
      if (id !== currentSlide) {
        Slideshow.changeSlide(id);
      }
    },
  },

  helpers: {
    disablePaginationControls() {
      Array.prototype.forEach.call($ui.navigationItems, function(el) {
        el.removeEventListener(
          'click',
          Slideshow.eventHandlers.navigationClickHandler
        );
      });
    },

    enablePaginationControls() {
      Array.prototype.forEach.call($ui.navigationItems, function(el) {
        el.addEventListener(
          'click',
          Slideshow.eventHandlers.navigationClickHandler
        );
      });
    },

    clearAllTimeouts() {
      clearTimeout(slideShowTimeout);
      clearTimeout(slideHideTimeout);
    },

    slideIncrement() {
      if (currentSlide < SLIDE_COUNT) {
        currentSlide++;
      } else {
        currentSlide = 1;
      }
    },

    slideDecrement() {
      if (currentSlide !== 1) {
        currentSlide--;
      } else {
        currentSlide = SLIDE_COUNT;
      }
    },

    showSlide(index) {
      Array.prototype.forEach.call(
        Array.prototype.filter.call($ui.slides, function(el) {
          return el.classList.contains('slide_' + index);
        }),
        function(el) {
          el.style.display = 'flex';
        }
      );
    },

    addSlideHideAnimation(index) {
      Array.prototype.forEach.call(
        Array.prototype.filter.call($ui.slides, function(el) {
          return el.classList.contains('slide_' + index);
        }),
        function(el) {
          el.classList.add(s.slide_hide);
        }
      );
    },

    hideAllSlides() {
      Array.prototype.forEach.call($ui.slides, function(el) {
        el.style.display = 'none';
      });
    },

    removeAllSlideHideAnimation() {
      Array.prototype.forEach.call($ui.slides, function(el) {
        el.classList.remove(s.slide_hide);
      });
    },

    /**
     * Turns slide animation ON. It is turned OFF on page load.
     */
    enableScreenshotAnimation() {
      Array.prototype.forEach.call($ui.slides, function(el) {
        el.classList.remove(s.slide_static);
      });
    },

    activateNavigationItem(index) {
      // Making all navigation items inactive.
      Array.prototype.forEach.call($ui.navigationItems, function(el) {
        el.classList.remove(s.item_active);
      });
      // Making given navigation items active.
      Array.prototype.forEach.call(
        Array.prototype.filter.call($ui.navigationItems, function(el) {
          return el.classList.contains('item_' + index);
        }),
        function(el) {
          el.classList.add(s.item_active);
        }
      );
    },
  },

  /**
   * Shows next slide or fist slide if current slide is last.
   */
  nextSlide() {
    Slideshow.helpers.clearAllTimeouts();
    Slideshow.helpers.addSlideHideAnimation(currentSlide);
    Slideshow.helpers.slideIncrement();
    Slideshow.helpers.enableScreenshotAnimation();
    Slideshow.autorun(SLIDE_CHANGE_ANIMATION_DURATION);
  },

  /**
   * Shows previous slide or last slide if current slide is first.
   */
  prevSlide() {
    Slideshow.helpers.clearAllTimeouts();
    Slideshow.helpers.addSlideHideAnimation(currentSlide);
    Slideshow.helpers.slideDecrement();
    Slideshow.helpers.enableScreenshotAnimation();
    Slideshow.autorun(SLIDE_CHANGE_ANIMATION_DURATION);
  },

  /**
   * Shows slide specified by index.
   */
  changeSlide(id) {
    Slideshow.helpers.clearAllTimeouts();
    Slideshow.helpers.addSlideHideAnimation(currentSlide);
    currentSlide = id;
    Slideshow.helpers.enableScreenshotAnimation();
    Slideshow.autorun(SLIDE_CHANGE_ANIMATION_DURATION);
    Slideshow.helpers.disablePaginationControls();
    setTimeout(() => {
      Slideshow.helpers.enablePaginationControls();
    }, SLIDE_CHANGE_ANIMATION_DURATION);
  },

  /**
   * Runs automatic slide change.
   */
  autorun(delay) {
    let firstIteration = true;
    if (delay) {
      Slideshow.helpers.activateNavigationItem(currentSlide);
    }
    slideShowTimeout = setTimeout(function slide() {
      if (!firstIteration) {
        Slideshow.helpers.slideIncrement();
      }
      Slideshow.helpers.hideAllSlides();
      Slideshow.helpers.removeAllSlideHideAnimation();
      Slideshow.helpers.showSlide(currentSlide);
      if (!delay || (delay && !firstIteration)) {
        Slideshow.helpers.activateNavigationItem(currentSlide);
      }
      firstIteration = false;
      slideHideTimeout = setTimeout(() => {
        Slideshow.helpers.enableScreenshotAnimation();
        Slideshow.helpers.addSlideHideAnimation(currentSlide);
      }, SLIDE_SHOW_DURATION - SLIDE_CHANGE_ANIMATION_DURATION);
      slideShowTimeout = setTimeout(slide, SLIDE_SHOW_DURATION);
    }, delay ? delay : 0);
  },
};

function initialize(ui) {
  $ui.slides = ui.slides;
  $ui.navigationItems = ui.navigationItems;
  // Slideshow keyboard control.
  window.addEventListener('keydown', Slideshow.eventHandlers.keyboardHandler);
  // Slideshow pagination control.
  Array.prototype.forEach.call($ui.navigationItems, function(el) {
    el.addEventListener(
      'click',
      Slideshow.eventHandlers.navigationClickHandler
    );
  });
  Slideshow.autorun();
}

export default class SlideshowComponent extends Component {
  componentDidMount() {
    const elements = {
      slides: document.getElementsByClassName('slide'),
      navigationItems: document.getElementsByClassName('navigation__item'),
    };

    initialize({
      slides: elements.slides,
      navigationItems: elements.navigationItems,
    });
  }

  render() {
    return (
      <div
        className={
          s.content + ' ' + s.slides + ' ' + s[this.props.type + '_slides']
        }
      >
        <div className={s['fixed-slide']}>
          <div className={s['graphic-content']} />
          <div className={s['text-content']}>
            <h1 className={s.logo}>
              <a
                href={
                  'https://www.rambler.ru/' +
                  '?utm_source=soft&utm_content=head&utm_medium=promo_' +
                  this.props.data.domainName +
                  '&utm_campaign=self_promo'
                }
                target="_blank"
                className={s.logo__link + ' ' + s.link_head}
                data-weather="logo::rambler"
                data-logo="header::logo_rambler"
              />
              <a
                href={
                  'https://' +
                  this.props.data.domainName +
                  '.rambler.ru/' +
                  '?utm_source=soft&utm_content=head&utm_medium=promo_' +
                  this.props.data.domainName +
                  '&utm_campaign=self_promo'
                }
                target="_blank"
                className={s.logo__link + ' ' + s.link_weather}
                data-weather="logo::weather"
                data-logo={'header::logo_' + this.props.data.domainName}
              />
            </h1>
            <div className={s['store-link']}>
              <StoreLink data={this.props.data} />
            </div>
            <a
              href="/browser-exntensions"
              className={s.back}
              data-soft={'product::' + this.props.type + '::back'}
            >
              К списку расширений
            </a>
          </div>
        </div>
        <div className={s['dummy-slide']} />
        {this.props.data.slidesContent.map((element, index) => (
          <div
            key={index}
            className={
              'slide' +
              ' ' +
              s.slide +
              ' ' +
              'slide_' +
              (index + 1) +
              ' ' +
              s['slide_' + (index + 1)] +
              ' ' +
              s.slide_static
            }
          >
            <div className={s['graphic-content']}>
              <div className={s.background} />
              <div className={s.icon} />
              <div className={s.screenshot} />
            </div>
            <div className={s['text-content']}>
              <div className={s['title-and-description']}>
                <h2
                  className={s.title}
                  dangerouslySetInnerHTML={{ __html: element.title }}
                />
                <p
                  className={s.description}
                  dangerouslySetInnerHTML={{ __html: element.description }}
                />
              </div>
            </div>
          </div>
        ))}
        <ul className={s.navigation}>
          {this.props.data.slidesContent.map((element, index) => (
            <li
              key={index}
              className={
                'navigation__item' +
                ' ' +
                s.navigation__item +
                ' ' +
                'item_' +
                (index + 1) +
                ' ' +
                s['item_' + (index + 1)]
              }
              data-id={index + 1}
              data-soft={
                'product::' +
                this.props.data.domainName +
                '::dots::' +
                (index + 1)
              }
            >
              <span
                className={s.navigation__link}
                data-weather={'paging::' + (index + 1)}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
