import React, { Component } from 'react';

import s from './index.scss';

/**
 * Adds extension quick install logic after click on specified DOM element.
 */
function initialize(el, config) {
  const userAgent = navigator.userAgent;
  let storeLink;
  if (userAgent.indexOf('Firefox') !== -1 && config.firefoxExtensionUrl) {
    storeLink = config.firefoxExtensionUrl;
  } else if (
    userAgent.indexOf('Opera') !== -1 ||
    (userAgent.indexOf('OPR') !== -1 && config.operaExtensionUrl)
  ) {
    storeLink = config.operaExtensionUrl;
  } else {
    if (config.chromeExtensionUrl) {
      storeLink = config.chromeExtensionUrl;
    }
  }
  if (storeLink) {
    el.setAttribute('href', storeLink);
  }
}

export default class StoreLink extends Component {
  componentDidMount() {
    const el = document.getElementsByClassName('store-link');

    initialize(el[0], this.props.data);
  }

  render() {
    return (
      <a
        href={this.props.data.chromeExtensionUrl}
        target="_blank"
        className={
          'store-link' + ' ' + s['store-link'] + ' ' + s['store-link_active']
        }
        data-weather="install"
      >
        Установить
      </a>
    );
  }
}
